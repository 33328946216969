<script setup lang="ts">
</script>
<template>
  <div
    class="parameters"
    id="parameters"
  >
    <div class="parameters-content">
      <div class="parameters-content-text">
        <h1 class="parameters-content-text-title">Определяем <br>параметры проекта</h1>
        <p class="parameters-content-text-description">
          Подбираем ключевые слова, максимально подходящие <br>
          вашему бизнесу. Собираем информацию о <br>
          потребителях: анализируем поведение пользователей, <br>
          их интересы и увлечения.
        </p>
        <div class="parameters-content-left">
          <img
            class="parameters-content-left-image"
            src="@/assets/images/Artboard-16.webp"
            alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.parameters {
  margin-top: get-vw(400px);
  @media (orientation: landscape){
    margin-top: get-vw(200px);
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    @media (orientation: landscape){
      width: 65%;
    }
    &-left {
      width: 100%;
      &-image {
        margin-left: -7%;
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      margin-left: get-vw(50px);
      width: 100%;
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: get-vw(150px);
        line-height: 85.52%;
        color: #000000;
        position: relative;
        @media (orientation: landscape){
          font-size: get-vw(110px);
        }
      }
      &-title::before {
        content: '';
        display: block;
        position: absolute;
        //top: 110%;
        bottom: get-vw(-50px);
        width: 31%;
        border-bottom: get-vw(10px) solid #4C4EF9;
      }
      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(65px);
        line-height: get-vw(90px);
        color: #000000;
        margin-top: get-vw(130px);
        white-space: nowrap;
        @media (orientation: landscape){
          font-size: get-vw(40px);
          line-height: get-vw(60px);
        }
      }
    }
  }
}
</style>
